.root {
  flex-grow: 1;
  height: 100vh;
}

.title {
  flex-grow: 1;
}

.contentContainer {
  padding-top: 24px;
  display: flex;
  justify-content: space-between;
  width: 95%;
  height: calc(100vh - 88px);
  flex: 1;
  margin-left: auto;
  margin-right: auto;

  .decryptContainer {
    display: flex;
    flex-direction: column;
    width: 49%;
    height: 100%;

    .keyField {
      margin: 24px 0;
    }

    .decryptButton {
      margin-bottom: 24px;
    }
  }

  .encryptdField {
    width: 49%;
    height: 100%;
  }
}
